<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
  >
    <v-card class="pa-1">
      <v-card-title class="mb-2">
        <div>{{ $t('actions|edit_workbenches') }}</div>
        <v-spacer />
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div
          v-for="item in currentCaseForm.workbenches"
          :key="item._id"
          class="mx-2 mt-2"
        >
          <v-text-field
            v-model="item.name"
            outlined
            dense
            :label="$t('mixed|workbench_name')"
            clearable
            class="mt-1"
            :placeholder="item.name"
          >
            <template slot="append-outer">
              <v-icon
                :disabled="currentCaseForm.workbenches.length < 2"
                color="primary"
                @click="deleteWorkbenchInCaseModel(item)"
              >
                mdi-trash-can
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mt-n5">
        <v-btn
          color="primary"
          rounded
          small
          min-width="120"
          class="mb-2"
          @click="changeWorkbenchName(); closeDialog()"
        >
          {{ $t('actions|update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    currentCaseForm: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      dialog: false,
      name: ''
    }
  },
  computed: {
    ...mapState({
      caseForms: state => state.caseForms.caseForms
    }),
    currentWorkbenches () {
      return Object.values(this.currentCaseForm.workbenches).map((w) => {
        return w.name
      })
    },
    actualWorkbenchIds () {
      return Object.values(this.currentCaseForm.workbenches).map((w) => {
        return w._id
      })
    }
  },
  watch: {
    currentCaseForm: {
      handler (name) {
        this.name = name.workbenches || {}
      },
      immediate: true
    }
  },
  created () {
    EventBus.$on('dialog-edit-case-models-workbenches', this.onEditCaseModelsCategoriesEvent)
  },
  beforeDestroy () {
    EventBus.$off('dialog-edit-case-models-workbenches', this.onEditCaseModelsCategoriesEvent)
  },
  methods: {
    ...mapActions({
      updateCaseForm: 'caseForms/updateCaseForm',
      updateWorkbench: 'caseForms/updateWorkbench',
      deleteWorkbench: 'caseForms/deleteWorkbench'
    }),
    saveWorkbenchName () {
      this.name.forEach(this.changeWorkbenchName)
    },
    async changeWorkbenchName () {
      this.loading = true
      const arr = this.currentCaseForm.workbenches.reduce((acc, wb) => {
        acc.push({
          _id: wb._id,
          name: wb.name
        })
        return acc
      }, [])
      try {
        for (let i = 0; i < arr.length; i++) {
          const workbenchId = arr[i]._id
          const payload = {
            name: arr[i].name
          }
          await this.updateWorkbench({ _id: this.currentCaseForm._id, workbenchId, payload })
        }
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    },
    async deleteWorkbenchInCaseModel (item) {
      await this.$dialog.confirm({
        text: this.$t('common|are_you_sure'),
        title: this.$t('common|warning'),
        cancelable: true
      })
      this.loading = true
      const ID = item._id
      try {
        await this.deleteWorkbench({ _id: this.currentCaseForm._id, workbenchId: ID })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    },
    removeField (i, field) {
      field.splice(i, 1)
    },
    onEditCaseModelsCategoriesEvent (event) {
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    }
  }
}

</script>

<style>
.vdialognew {
  box-shadow: none !important;
}
</style>
